import React, { useEffect, useMemo, useState } from 'react';
import {
    Typography,
    Table,
    Spin,
    Button,
    Empty,
    Tag,
    Avatar,
    Card
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import DetailDrawer from '../DetailDrawer';
import { GET_POSTED_TRUCKS } from '../../../queries';
import { UPDATE_POSTING } from '../../../mutations';
import momentify from '../../../Utilities/momentify';
import moment from 'moment'
import 'moment/locale/ja';
import TraboxDrawer from './TraboxDrawer';
import { authenticationService } from '../../../Utilities/authenticationService';
import { isMobile } from '../../../Utilities/isMobile';
import { MessageOutlined } from '@ant-design/icons';
import '../../../App.less'
import { taggify } from '../../../Utilities/taggify';
import { useParams } from 'react-router-dom';

const PostingTable = ({
    station,
    dateRange,
    refresh,
    setRefresh,
    history,
    searchText,
    companies,
    userCompany,
    onlyExpired = false
}) => {
    const { postingId } = useParams();
    const [originalPostedTrucks, setOriginalPostedTrucks] = useState([]);
    const [showTraboxDrawer, setShowTraboxDrawer] = useState(false);
    const [detail, setDetail] = useState();
    const [requestDrawerOpen, setRequestDrawerOpen] = useState(false);
    const currentUser = authenticationService.currentUserValue
    
    const { data, refetch, error, startPolling } = useQuery(GET_POSTED_TRUCKS, {
        variables: {
            search:  `end_date ${onlyExpired ? "<=" : ">="} '` + moment().format('YYYY-MM-DD') + "'" // onlyExpired shows postings that have already ended
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const trucks = data.postings.edges.map(posting => ({
                key: posting.node.id,
                id: posting.node.id,
                opsDate: posting.node.opsDate,
                startDate: moment(posting.node.startDate).format('YYYY-MM-DD HH:mm'),
                endDate: moment(posting.node.endDate).format('YYYY-MM-DD HH:mm'),
                station: posting.node.station.officialName,
                stationName: posting.node.station.officialName,
                stationId: posting.node.station.id,
                companyName: posting.node.user.company?.companyName,
                companyId: posting.node.user.company?.id,
                vehicleId: posting.node.truck.vehicleId,
                vehicleType: posting.node.truck.vehicleType,
                maxTruckCapacity: posting.node.truck.maxTruckCapacity,
                driverLicenseClass: posting.node.truck.driverLicenseClass,
                postedBy: {
                    id: posting.node.user.id,
                    name: `${posting.node.user.lastName} ${posting.node.user.firstName}`
                },
                postedByUserName: `${posting.node.user.lastName} ${posting.node.user.firstName}`,
                userId: posting.node.user.id,
                postedDate: moment(posting.node.createdAt).format('YYYY-MM-DD HH:mm'),
                vehicle: {
                    key: posting.node.truck.id,
                    id: posting.node.truck.id,
                    date: posting.node.opsDate,
                    station: posting.node.station.officialName,
                    stationId: posting.node.station.id,
                    vehicleType: posting.node.truck.vehicleType,
                    vehicleId: posting.node.truck.vehicleId,
                    maxTruckCapacity: posting.node.truck.maxTruckCapacity,
                    driverLicenseClass: posting.node.truck.driverLicenseClass
                },
                comments: posting.node.truckPostingComments.map(comment => ({
                    id: comment.id,
                    postingId: posting.node.id,
                    fromId: comment.fromUser.id,
                    toId: comment.toUser?.id,
                    author: <Typography.Text strong>{`${comment.fromUser.lastName} ${comment.fromUser.firstName}`}</Typography.Text>,
                    avatar: <Avatar>{comment.fromUser.lastName[0]}</Avatar>,
                    content: (
                        <div key={comment.id} style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>{comment.commentText}</p>
                            <span style={{ fontSize: '0.8em', color: '#888', alignSelf: parseInt(comment.fromUser.id) !== parseInt(currentUser.id) ? 'flex-start' : 'flex-end' }}>
                                {moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm')}
                            </span>
                        </div>
                    ),
                })),
                traboxStatus: posting.node.traboxStatus,
                routesForecastId: posting.node.routesForecastId,
                status: posting.node.status,
                memo: posting.node.memo,
                deliverer: posting.node.deliverer,
            }))
            setOriginalPostedTrucks(trucks);
            startPolling(10000);
            if (detail) {
                setDetail(trucks.find(posting => posting.id === detail.id))
            }
        }
    });

    const [updatePosting] = useMutation(UPDATE_POSTING, {
        onCompleted: (data) => {
            refetch()
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const handleDelete = (id) => {
        updatePosting({
            variables: {
                input: {
                    id,
                    status: 'archived'
                }
            }
        })
    }
    useEffect(() => {
        if (refresh) {
            refetch();
            setRefresh(false);
        }
    }, [refresh, refetch, setRefresh])

    useEffect(() => {
        if (postingId && originalPostedTrucks.length) {
            setDetail(originalPostedTrucks.find(posting => posting.id === postingId))
            setRequestDrawerOpen(true)
        }
    }, [postingId, originalPostedTrucks])

    const columns = [
        {
            render: rowContent => <Button icon={<MessageOutlined/>} onClick={() => {setRequestDrawerOpen(true); setDetail(rowContent)}}/>,
            width: 25
        },
        {
            title: <Typography.Text strong>ID</Typography.Text>,
            dataIndex: 'id',
            width: 25
        },
        {
            title: <Typography.Text strong>運行日</Typography.Text>,
            dataIndex: 'opsDate',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: 50
        },
        {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'startDate',
            width: 50
        },
        {
            title: <Typography.Text strong>到着時刻</Typography.Text>,
            dataIndex: 'endDate',
            width: 50
        },
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationName',
            width: 65
        }, 
        {
            dataIndex: 'companyName',
            title: <Typography.Text strong>会社名</Typography.Text>,
            width: 50
        },
        {
            title: <Typography.Text strong>車種名</Typography.Text>,
            dataIndex: 'vehicleType',
            sorter: (a, b) => a.vehicleType && b.vehicleType && a.vehicleType.localeCompare(b.vehicleType, 'ja'),
            width: 50
        }, {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
            width: 50
        }, {
            title: <Typography.Text strong>積載重量</Typography.Text>,
            dataIndex: 'maxTruckCapacity',
            sorter: (a, b) => a.maxTruckCapacity - b.maxTruckCapacity,
            width: 65
        }, {
            title: <Typography.Text strong>免許タイプ</Typography.Text>,
            dataIndex: 'driverLicenseClass',
            sorter: (a, b) => a.driverLicenseClass - b.driverLicenseClass,
            width: 50
        },
        {
            title: <Typography.Text strong>投稿者</Typography.Text>,
            dataIndex: 'postedBy',
            sorter: (a, b) => a.driverLicenseClass - b.driverLicenseClass,
            width: 50,
            render: rowContent => <div>{rowContent.name}</div>
        },
        {
            title: <Typography.Text strong>投稿日</Typography.Text>,
            dataIndex: 'postedDate',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: rowContent => momentify(rowContent),
            width: 50
        },
        {
            title: <Typography.Text strong>ステータス</Typography.Text>,
            dataIndex: 'status',
            width: 40,
            render: taggify
        },
        {
            title: <Typography.Text strong>備考</Typography.Text>,
            dataIndex: 'memo',
            width: 100
        },
        {
            render: rowContent => currentUser.id === parseInt(rowContent.userId) && <Button type='link' onClick={()=> handleDelete(rowContent.id)}>削除する</Button>,
            width: 50,
            fixed: 'right'
        },
        ...(userCompany === 'ダイセーロジスティクス' ? [
            {
                title: <Typography.Text strong>トラボックス進捗</Typography.Text>,
                render: (rowContent, record) => {
                    if (!rowContent) {
                        return <Button type='link' onClick={()=> {setShowTraboxDrawer(true); setDetail(record)}}>トラボックスに送信</Button>
                    } else if (rowContent === 'sent') {
                        return <Tag color='blue'>処理中</Tag>
                    } else if (rowContent === 'success') {
                        return <Tag color='green'>投稿完了</Tag>
                    } else if (rowContent === 'failed') {
                        return <Tag color='red'>投稿失敗</Tag>
                    }
                },
                dataIndex: 'traboxStatus',
                width: 100,
                fixed: 'right'
            },
        ] : [])
    ]

    const filteredPostedTrucks = useMemo(() => {

        let filteredTrucks = [...originalPostedTrucks]
        if (station.length > 0) {
            filteredTrucks = filteredTrucks.filter(truck => station.includes(truck.stationId))
        }
        if (dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            filteredTrucks = filteredTrucks.filter(truck => {
                return moment(startDate).isSameOrBefore(truck.startDate, 'minute') && moment(endDate).isSameOrAfter(truck.endDate, 'minute')
            })
        }
        if (searchText.length > 0) {
            filteredTrucks = filteredTrucks.filter(truck => {
                return Object.values(truck).some(value => value?.toString().toLowerCase().includes(searchText.toLowerCase()))
            })
        }
        
        if (companies.length > 0) {
            filteredTrucks = filteredTrucks.filter(truck => companies.includes(truck.companyId))
        }

        // Sort by opsDate in descending order
        filteredTrucks.sort((a, b) => new Date(b.opsDate) - new Date(a.opsDate));

        return filteredTrucks;

    }, [station, dateRange, originalPostedTrucks, searchText, companies])
    
    const TraboxButton = (posting) => <Button onClick={()=> {setShowTraboxDrawer(true); setDetail(posting)}}>トラボックスに送信</Button>
    // use card to show details of the posting
    const PostingListForMobile = () => {
        return (
            <div style={{marginBottom: '5rem'}}>
                {filteredPostedTrucks.map(posting => (
                    <Card key={posting.id} style={{margin: '.5rem', padding: '1rem', border: '1px solid #f0f0f0', borderRadius: '5px'}}>
                        <Button icon={<MessageOutlined/>} onClick={() => {setRequestDrawerOpen(true); setDetail(posting)}}/><br/>
                        <Typography.Text strong>運行日: </Typography.Text><Typography.Text>{posting.opsDate}</Typography.Text><br/>
                        <Typography.Text strong>出発時刻: </Typography.Text><Typography.Text>{posting.startDate}</Typography.Text><br/>
                        <Typography.Text strong>到着時刻: </Typography.Text><Typography.Text>{posting.endDate}</Typography.Text><br/>
                        <Typography.Text strong>営業所: </Typography.Text><Typography.Text>{posting.stationName}</Typography.Text><br/>
                        <Typography.Text strong>会社名: </Typography.Text><Typography.Text>{posting.companyName}</Typography.Text><br/>
                        <Typography.Text strong>車種名: </Typography.Text><Typography.Text>{posting.vehicleType}</Typography.Text><br/>
                        <Typography.Text strong>車両番号: </Typography.Text><Typography.Text>{posting.vehicleId}</Typography.Text><br/>
                        <Typography.Text strong>積載重量: </Typography.Text><Typography.Text>{posting.maxTruckCapacity}</Typography.Text><br/>
                        <Typography.Text strong>免許タイプ: </Typography.Text><Typography.Text>{posting.driverLicenseClass}</Typography.Text><br/>
                        <Typography.Text strong>投稿者: </Typography.Text><Typography.Text>{posting.postedBy.name}</Typography.Text><br/>
                        <Typography.Text strong>投稿日: </Typography.Text><Typography.Text>{posting.postedDate}</Typography.Text><br/>
                        <Typography.Text strong>ステータス: </Typography.Text><Typography.Text>{taggify(posting.status)}</Typography.Text><br/>
                        {userCompany === 'ダイセーロジスティクス' && (<><Typography.Text strong>トラボックス進捗: </Typography.Text><Typography.Text>{posting.traboxStatus || '未送信'}</Typography.Text><br/></>)}
                        {userCompany === 'ダイセーロジスティクス' && (TraboxButton(posting))}
                    </Card>
                ))}
            </div>
        )
    }

    if (error) {
        return <Empty style={{marginTop: "20vh"}}/>
    }

    if (!data) {
        return <Spin/>;
    }

    return (<>
    {isMobile() ? <PostingListForMobile/> : 
        <Table
            columns={columns}
            style={{ padding: '24px', background: '#fff' }}
            dataSource={filteredPostedTrucks}
            scroll={{ x: 1300 }}
        />
    }
        <TraboxDrawer
            drawerOpen={showTraboxDrawer}
            setDrawerOpen={setShowTraboxDrawer}
            posting={detail}
            refetch={refetch}
            history={history}
        />

       {requestDrawerOpen && <DetailDrawer
            setVisible={setRequestDrawerOpen}
            visible={requestDrawerOpen}
            handleDeletePosting={handleDelete} 
            posting={detail} 
            refetchPostings={refetch}
            buttonMode={true} 
            traboxButton={TraboxButton(detail)}
            updatePosting={updatePosting}
        />}

    </>)
};

export default PostingTable;
