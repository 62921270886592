import React, { useMemo } from "react";
import { 
    Table, 
    Typography, 
    Spin, 
    Button,
    message } from "antd";
import { SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { CREATE_POSTING } from '../../../mutations';
import moment from "moment";

// Recommendation Table component
const RecommendationTable = ({
    recommendations,
    loadingRecommendations,
    stationsData,
    stationFilter,
    companyFilter,
    dateRangeFilter,
    searchText,
}) => {

    // Helper function to get the station name and company name by stationId
    function getStationAndCompanyName(stationId, stationsData) {
        const station = stationsData.allStations.edges.find(station => parseInt(station.node.id, 10) === stationId);
        if (station) {
            const { stationName } = station.node;
            const companyName = station.node.company?.companyName || "Unknown Company";
            return { stationName, companyName };
        }
        return { stationName: "Unknown Station", companyName: "Unknown Company" };
    }

    const [createPosting] = useMutation(CREATE_POSTING, {
        onCompleted: () => {    
            message.success('空きトラック掲示板に投稿しました。');
        },
        onError: (error) => {
            message.error('その車両は既にポストされています');
        }
    });

    // Function to handle the posting creation based on the clicked row
    const handleCreatePosting = (record) => {
        createPosting({
            variables: {
                input: {
                    vehicleId: record.vehicleId,
                    stationId: record.stationId,
                    opsDate: moment(record.departureTime).format('YYYY-MM-DD'),
                    startDate: moment(record.departureTime).format("YYYY-MM-DDTHH:mm:ssZ"),
                    endDate: moment(record.arrivalTime).format("YYYY-MM-DDTHH:mm:ssZ"),
                    status: "available"
                }
            }
        });
    };

    // Filter recommendations based on the search inputs
    const filteredRecommendations = useMemo(() => {
        let filteredRecs = [...recommendations];
        if (stationFilter.length > 0) {
            filteredRecs = filteredRecs.filter(rec => stationFilter.includes(rec.stationId.toString()));
        }
        if (companyFilter.length > 0) {
            filteredRecs = filteredRecs.filter(rec => companyFilter.includes(rec.companyId.toString()));
        }
        if (dateRangeFilter.length === 2) {
            const [startDate, endDate] = dateRangeFilter;
            filteredRecs = filteredRecs.filter(rec => {
                return moment(startDate).isSameOrBefore(rec.departureTime, 'minute') && moment(endDate).isSameOrAfter(rec.arrivalTime, 'minute');
            });
        }
        if (searchText.length > 0) {
            filteredRecs = filteredRecs.filter(rec => {
                return Object.values(rec).some(value => value?.toString().toLowerCase().includes(searchText.toLowerCase()));
            });
        }

        return filteredRecs;
    }, [stationFilter, companyFilter, dateRangeFilter, recommendations, searchText]);


    const columns = [
        {
            render: (_, record) => (
                <Button 
                    icon={<SendOutlined />} 
                    onClick={() => handleCreatePosting(record)}
                />
            ),
            width: 20
        },
        {
            title: <Typography.Text strong>運行日</Typography.Text>,
            dataIndex: 'departureTime',
            key: 'opsDate',
            sorter: (a, b) => new Date(a.departureTime) - new Date(b.departureTime),
            defaultSortOrder: 'ascend', // Set the default sorting to ascending order
            render: (text) => new Date(text).toLocaleDateString('ja-JP'), 
            width: 50
        },
        {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'departureTime',
            key: 'departureTime',
            render: (text) => new Date(text).toLocaleString(), // Format departure_time
            width: 50
        },
        {
            title: <Typography.Text strong>到着時刻</Typography.Text>,
            dataIndex: 'arrivalTime',
            key: 'arrivalTime',
            render: (text) => new Date(text).toLocaleString(), // Format arrival_time
            width: 50
        },
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationId',
            key: 'stationId',
            render: (stationId) => {
                const { stationName } = getStationAndCompanyName(stationId, stationsData);
                return stationName;
            },
            width: 50
        },
        {
            title: <Typography.Text strong>会社名</Typography.Text>,
            dataIndex: 'companyId',
            key: 'companyId',
            render: (stationId) => {
                const { companyName } = getStationAndCompanyName(stationId, stationsData);
                return companyName;
            },
            width: 50
        },
        {
            title: <Typography.Text strong>車種名</Typography.Text>,
            dataIndex: 'vehicleType',
            key: 'vehicleType',
            sorter: (a, b) => a.vehicleType && b.vehicleType && a.vehicleType.localeCompare(b.vehicleType, 'ja'),
            width: 50
        },
        {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
            key: 'vehicleId',
            width: 50
        },
        {
            title: <Typography.Text strong>積載重量</Typography.Text>,
            dataIndex: 'maxTruckCapacity',
            key: 'maxTruckCapacity',
            sorter: (a, b) => a.maxTruckCapacity - b.maxTruckCapacity,
            width: 50
        },
        {
            title: <Typography.Text strong>免許タイプ</Typography.Text>,
            dataIndex: 'driverLicenseClass',
            key: 'driverLicenseClass',
            width: 50
        },
    ];

    if (loadingRecommendations) {
        return <Spin />;
    }

    return <Table dataSource={filteredRecommendations} columns={columns} rowKey="vehicle_id" />;
};

export default RecommendationTable;
