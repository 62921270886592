import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Descriptions, Modal } from 'antd';

const NewCompanyModal = ({ companyInfo }) => {
    const [visible, setVisible] = useState(true);

    return (
        <Modal
            title="追加された会社"
            open={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Descriptions layout='horizontal'>
                <Descriptions.Item label='会社名' span={3}>{companyInfo.companyName}</Descriptions.Item>
                <Descriptions.Item label='備考' span={3}>{companyInfo.memo}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

NewCompanyModal.propTypes = {
    companyInfo: propTypes.object
};

export default NewCompanyModal;
