import React, { useState, useEffect, useMemo } from "react";
import { Space, Card, Tabs, Divider, Form, Row, Col, Select, DatePicker, Button, Spin, Input } from "antd";
import PostingTable from "./PostingTable/PostingTable";
import RecommendationTable from "./PostingTable/RecommendTable";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STATIONS, GET_COMPANY, GET_RECOMMENDATIONS, GET_ALL_STATIONS} from "../../queries";
import { CREATE_SEARCH_HISTORY } from "../../mutations"; // Ensure you have the mutation imported
import moment from "moment";
import { useParams } from 'react-router-dom';
import { localeInDatepicker } from "../../Utilities/datepickerWithTimeInForm";
import { isMobile } from "../../Utilities/isMobile";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useStickyState } from "../../Utilities/useStickyState";
import HeatmapRequestModal from "../TruckSchedule/HeatmapRequestModal";

const TruckPosting = () => {
    const { fromDate, toDate, station: paramsStation, company: paramsCompany } = useParams();
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const { data: companiesData, loading: loadingCompanies } = useQuery(GET_COMPANY);
    const { data: allStationsData } = useQuery(GET_ALL_STATIONS);
    const [createSearchHistory] = useMutation(CREATE_SEARCH_HISTORY); // Initialize the mutation
    const [stations, setStations] = useState(paramsStation ? [paramsStation] : []);
    const [companies, setCompanies] = useState(paramsCompany ? [paramsCompany] : []);
    const [dateRange, setDateRange] = useState((fromDate && toDate && [moment(fromDate), moment(toDate)]) || []);
    const [searchText, setSearchText] = useState('');   
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();
    const [recommendations, setRecommendations] = useState([]); // State for recommendations
    const [loadingRecommendations, setLoadingRecommendations] = useState(true);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [tabFilter, setTabFilter] = useStickyState('ongoing', 'truckPostingTabFilter');
    const [newRequestDrawerOpen, setNewRequestDrawerOpen] = useState(false);

    const currentUserCompanyName = useMemo(() => {
        if (!companiesData) return null;
        const company = companiesData.companies.edges.find(
            company => String(company.node.id) === String(currentUser.company_id)
        );
        return company?.node.companyName || null;
    }, [companiesData, currentUser]);

    const handleSearch = async ({ dateRange, station, company, searchText }) => {
        // Check if at least one of the inputs (dateRange, station, or searchText) is filled
        if ((!station || station.length === 0) && (!dateRange || dateRange.length === 0) && !searchText && !company) {
            console.warn('No search inputs provided. Skipping search history creation.');
            return;
        }

        // Set the form data to state
        setStations(station.find(e => e === 'all') ? stationsData.stations.edges.map(e => e.node.id) : station);
        setCompanies(company.find(e => e === 'all') ? companiesData.companies.edges.map(e => e.node.id) : company);
        setDateRange(dateRange);
        setSearchText(searchText);

        // Prepare date variables safely
        const startDate = dateRange && dateRange[0] ? dateRange[0].toISOString() : null;
        const endDate = dateRange && dateRange[1] ? dateRange[1].toISOString() : null;
        // Convert station and company lists from strings to integers
        const parsedStationIds = station.map((id) => parseInt(id, 10)); // Convert each ID to an integer
        const parsedCompanyIds = company.map((id) => parseInt(id, 10)); // Convert each ID to an integer

        // Call the mutation to create search history
        try {
            await createSearchHistory({
                variables: {
                    input: {
                        userId: currentUser.id, // Replace with actual user ID
                        companyIds: parsedCompanyIds, // Replace with actual company ID if available
                        stationIds: parsedStationIds, // Assuming the first station is used, adjust as necessary
                        startDate: startDate,
                        endDate: endDate,
                        searchText: searchText || "",
                    },
                },
            });
            console.log('Search history created successfully');
        } catch (error) {
            console.error('Error creating search history:', error);
        }
    };

    // Query to get recommendations
    const { loading, error } = useQuery(GET_RECOMMENDATIONS, {
        variables: { userId: currentUser.id },
        onCompleted: (data) => {
            if (data && data.recommendTrucks) {
                setRecommendations(data.recommendTrucks);
            } else {
                console.log("Error recommendation fetch")
            }
            setLoadingRecommendations(false);
        },
    });

    useEffect(() => {
        if (loading) {
            setLoadingRecommendations(true);
        } else if (error) {
            console.error("Error fetching recommendations:", error);
            setLoadingRecommendations(false);
        }
    }, [loading, error]);

    const SearchErea = () => {
        return <Card bordered={false}>
                <Form
                    form={form}
                    layout='inline'
                    size='large'
                    onFinish={handleSearch}
                >
                    <Row gutter={[16, 8]}>
                        <Col>
                            <Form.Item name='company' label={isMobile() ? "" : "会社名"} initialValue={stations || []}>
                                <Select
                                    showSearch
                                    initialValue={companies || []}
                                    mode="multiple"
                                    placeholder="会社名を選択してください"
                                    style={{ width: 250 }}
                                >
                                    {
                                        companiesData?.companies?.edges.map(company => (
                                            <Select.Option key={company.node.id} value={company.node.id}>
                                                {company.node.companyName}
                                            </Select.Option>
                                        )).concat([<Select.Option key='all' value='all'>全ての会社名</Select.Option>])
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='station' label={isMobile() ? "" : "営業所"} initialValue={stations || []}>
                                <Select
                                    showSearch
                                    initialValue={stations || []}
                                    mode="multiple"
                                    placeholder="営業所を選択してください"
                                    style={{ width: 250 }}
                                >
                                    {
                                        stationsData?.stations?.edges.map(station => (
                                            <Select.Option key={station.node.id} value={station.node.id}>
                                                {station.node.officialName}
                                            </Select.Option>
                                        )).concat([<Select.Option key='all' value='all'>全ての営業所</Select.Option>])
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='dateRange' label={isMobile() ? "" : "表示期間"} initialValue={dateRange || []}>
                                <DatePicker.RangePicker
                                    locale={localeInDatepicker}
                                    initialValue={dateRange || []}
                                    showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]}}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="searchText" initialValue={searchText}>
                                <Input
                                    allowClear
                                    style={{ width: 200 }}
                                    value={searchText}
                                    placeholder="例：冷凍車、バン"
                                    prefix={<SearchOutlined />}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    () => (
                                        <>
                                            <Button
                                                disabled={
                                                    !form.getFieldValue('station') ||
                                                    !form.getFieldValue('dateRange')
                                                }
                                                htmlType="submit" type='primary'>
                                                検索
                                            </Button>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
    }
    const addButtonStyle = isMobile() ? {margin: '15px', zIndex: newRequestDrawerOpen ? 0 : 1001, top: 0, right: 0, position: 'fixed'} : {display: 'flex', justifyContent: 'flex-end'};
    const items = [
        {
            key: 'ongoing',
            label: '進行中',
            children: (
                <PostingTable
                    dateRange={dateRange}
                    station={stations}
                    companies={companies}
                    userCompany={currentUserCompanyName}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    searchText={searchText}
                />
            )
        },
        {
            key: 'previous', // todo: filter by postings that have expired
            label: '過去の投稿',
            children: (
                <PostingTable
                    dateRange={dateRange}
                    station={stations}
                    companies={companies}
                    userCompany={currentUserCompanyName}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    searchText={searchText}
                    onlyExpired={true}
                />
            )
        },
        // only show おすすめ for PC users 
        ...(!isMobile() ? [{
            key: 'recommendations',
            label: 'おすすめ',
            children: (
                <RecommendationTable
                    recommendations={recommendations}
                    loadingRecommendations={loadingRecommendations}
                    stationsData={allStationsData}
                    stationFilter={stations}
                    companyFilter={companies}
                    dateRangeFilter={dateRange}
                    searchText={searchText}
                />
            )
        }] : [])
    ];

    if (loadingStations || loadingCompanies) {
        return <Spin />;
    }

    return <Space direction="vertical" style={{ width: "100%" }}>
        <SearchErea />
        <Divider style={{ margin: 0, padding: 0 }}/>
        <Card bordered={false} style={{padding: '0px 0px 0px 0px'  }}>
            <div style={addButtonStyle}>
                <Button
                    type={isMobile() ? "text" : "primary"}
                    icon={<PlusOutlined />}
                    onClick={() => setNewRequestDrawerOpen(true)}
                    style={{ positon: 'absolute', top: '0'}}
                >
                    {!isMobile() && '掲示板に投稿する'}
                </Button>
            </div>
            <Tabs items={items} defaultActiveKey={tabFilter} onChange={ activeKey => setTabFilter(activeKey) }/>
        </Card>
        <HeatmapRequestModal
            visible={newRequestDrawerOpen}
            setVisible={setNewRequestDrawerOpen}
            truck={null}
            vehicleId={null}
            vehicleType={null}
            maxTruckCapacity={null}
            driverLicenseClass={null}
            route={null}
            stationsData={stationsData}
            showNewRequestTab={false}
            showPostingTab={true}
            refetch={refresh}
        />    
    </Space>;
    
}
export default TruckPosting;
