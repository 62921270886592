import React, { useState, useEffect }from 'react';
import {
    Drawer,
    Typography,
    Input,
    Button,
    Avatar,
    Popconfirm,
    message,
    Select,
    Divider,
    Row,
    Col,
    Form,
    Tag
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { taggify } from '../../Utilities/taggify';
import {
    UPDATE_FREIGHT_POSTING,
    CREATE_FREIGHT_POSTING_COMMENT,
    UPDATE_FREIGHT_POSTING_COMMENT,
    DELETE_FREIGHT_POSTING_COMMENT
} from '../../mutations';
import { GET_FREIGHT_POSTING_COMMENT, GET_FREIGHT_POSTING, GET_USERS } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { authenticationService } from '../../Utilities/authenticationService'
import { isMobile } from '../../Utilities/isMobile';
import CommentDrawer from './CommentDrawer';
import CommentList from './CommentList';

const DetailDrawer = ({
    setDetailDrawerOpen,
    detailDrawerOpen,
    postingId,
    refetchList,
    history
}) => {
    const currentUser = authenticationService.currentUserValue;
    const [comments, setComments] = useState([]);
    const [memo, setMemo] = useState('');
    const [deliverer, setDeliverer] = useState(null);
    const [posting, setPosting] = useState({});
    const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
    const [selectedCommenterId, setSelectedCommenterId] = useState(null);
    const [commenterIds, setCommenterIds] = useState([]);
    const [commenterUsers, setCommenterUsers] = useState([]);
    const [detailForm] = Form.useForm();
    
    const { loading: userListLoading, error: userListError, data: userListData } = useQuery(GET_USERS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });
    const { refetch } = useQuery(GET_FREIGHT_POSTING, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(postingId) },
        onCompleted: (data) => {
            if (data.freightPosting) {
                setPosting(
                    {
                        key: data.freightPosting.id,
                        id: data.freightPosting.id,
                        slipNumber: data.freightPosting.slipNumber,
                        deliveryDate: moment(data.freightPosting.deliveryDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                        pickupDate: moment(data.freightPosting.pickupDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                        stationName: data.freightPosting.station?.officialName,
                        stationId: String(data.freightPosting.stationId),
                        maxTruckCapacity: data.freightPosting.maxTruckCapacity,
                        vehicleType: data.freightPosting.vehicleType,
                        price: data.freightPosting.price,
                        postedBy: {
                            id: data.freightPosting.user?.id,
                            name: `${data.freightPosting.user?.lastName} ${data.freightPosting.user?.firstName}`,
                            user: data.freightPosting.user,
                        },
                        postedByUserName: `${data.freightPosting.user?.lastName} ${data.freightPosting.user?.firstName}`,
                        userId: data.freightPosting.user?.id,
                        companyName: data.freightPosting.company?.companyName,
                        postedDate: data.freightPosting.createdAt,
                        remarks: data.freightPosting.memo,
                        comments: data.freightPosting.freightPostingComments,
                        status: data.freightPosting.status,
                        traboxStatus: data.freightPosting.traboxStatus,
                        deliverer: data.freightPosting.deliverer,
                    }
                )
                setMemo(data.freightPosting.memo);
                setDeliverer(data.freightPosting.deliverer?.id || null);
            }
        }
    });

    const { refetch: refetchComment } = useQuery(GET_FREIGHT_POSTING_COMMENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(postingId) },
        onCompleted: (data) => {
            const fromUsers = data.freightPosting.freightPostingComments.map(comment => comment.fromUser);
            const toUsers = data.freightPosting.freightPostingComments.map(comment => comment.toUser);
            setCommenterUsers([...new Set([...fromUsers, ...toUsers])].filter(commenter => String(commenter.id) !== String(data.freightPosting?.user?.id)));
            const fromUserIds = data.freightPosting.freightPostingComments.map(comment => comment.fromUser.id);
            const toUserIds = data.freightPosting.freightPostingComments.map(comment => comment.toUser?.id);
            const commenters = [...new Set([...fromUserIds, ...toUserIds])];
            setCommenterIds(commenters.filter(commenter => String(commenter) !== String(data.freightPosting?.user?.id)));
            const commentArr = data.freightPosting.freightPostingComments.map(comment => ({
                id: comment.id,
                postingId: postingId,
                fromId: comment.fromUser.id,
                toId: comment.toUser?.id,
                author: <Typography.Text strong>{`${comment.fromUser.lastName} ${comment.fromUser.firstName}`}</Typography.Text>,
                avatar: <Avatar>{comment.fromUser.lastName[0]}</Avatar>,
                content: (
                    <div key={comment.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>{comment.commentText}</p>
                        <span style={{ fontSize: '0.8em', color: '#888', alignSelf: parseInt(comment.fromUser.id) !== parseInt(currentUser.id) ? 'flex-start' : 'flex-end' }}>
                            {moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm')} {comment.createdAt !== comment.updatedAt && '(編集済)'}
                        </span>
                    </div>
                ),
                datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
            }));
            setComments(commentArr.sort((a, b) => a.datetime > b.datetime ? 1 : -1));
        }
    });
    
    const [createFreightPostingComment] = useMutation(CREATE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetch();
            refetchComment();
        }
    });
    const [updateFreightPostingComment] = useMutation(UPDATE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetchComment();
        }
    });
    const [deleteFreightPostingComment] = useMutation(DELETE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetchComment();
        }
    });
    const [archiveFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            refetchList();
            setDetailDrawerOpen(false);
            message.success('リクエストを削除しました。')
        }
    });
    const [updateFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            refetchList();
            message.success('リクエストを更新しました。')
        }
    })

    const handlePostingNav = () => {
        const location = {
            pathname: '/truckSchedule',
            posting_id: posting.id,
            station_id: posting.deliverer?.station?.id,
            pickup_date: posting.pickupDate,
            delivery_date: posting.deliveryDate,
        }
        history.push(location)
    }
    const handlePostButton = (posting) => {
        if (posting.status === "draft") {
            return <Button
            type="primary"
            onClick={() => {
                updateFreightPosting({
                    variables: {
                        input: {
                            id: posting.id,
                            status: "posted",
                            memo: memo,
                        },
                    },
                });
            }}
        >
            投稿する
        </Button>
        }
    }
    const handleDriverSelect = (posting) => <>
        <Select
            showSearch
            placeholder="配送者を入力する"
            optionFilterProp="label"
            onChange={(e) => {
                setDeliverer(parseInt(e));
            }}
            className={!isMobile() && "custom-select"}
            style={{ border: !isMobile() && 'none', boxShadow: !isMobile() && 'none' }}
            dropdownStyle={{ border: !isMobile() && 'none' }}
        >
            {!userListLoading && !userListError &&
                userListData?.users?.edges?.map((user) => (
                    <Select.Option
                        key={user.node.id}
                        value={user.node.id}
                        label={`${user.node.lastName} ${user.node.firstName}`}
                    >
                        {`${user.node.lastName} ${user.node.firstName}`}
                    </Select.Option>
                ))
            }
        </Select>
        <Button
            type="primary"
            onClick={() => {
                updateFreightPosting({
                    variables: {
                        input: {
                            id: posting.id,
                            status: "requested",
                            delivererId: deliverer,
                        },
                    },
                });
            }}
        >
            決定
        </Button>
    </>
    const handleFinishDelivery = (posting) => {
        if (posting.status === "requested" && (currentUser.id === parseInt(deliverer) || currentUser.id === parseInt(posting.postedBy.id))) {
            return <Button
                type="primary"
                onClick={() => {
                    updateFreightPosting({
                        variables: {
                            input: {
                                id: posting.id,
                                status: "delivered",
                            },
                        },
                    });
                }}
            >
                配達完了
            </Button>
        }
    }
    const handleMemoInput = (posting) => <Input.TextArea
        placeholder="備考を入力してください。"
        minLength={10}
        style={{height: isMobile() ? 180 : '5rem', borderTop: !isMobile() && 'none'}}
        value={memo}
        disabled={posting.status !== "draft"}
        onChange={(e) => setMemo(e.target.value)}
    />
    const columnList = ['ID', '営業所', '伝票番号', '納品日時', '集荷日時', '積載重量', '車種', '運賃', '投稿者']
    const labelNameMapping = {
        'ID': 'id',
        '営業所': 'stationName',
        '伝票番号': 'slipNumber',
        '納品日時': 'pickupDate',
        '集荷日時': 'deliveryDate',
        '積載重量': 'maxTruckCapacity',
        '車種': 'vehicleType',
        '運賃': 'price',
        '投稿者': 'postedByUserName'
    }
    const readOnlyStyle = {border: 'none', borderBottom: '1px solid lightgray', width: '100%'}
    const labelStyle = {
        padding: '0'
    };
    useEffect(() => {
        if (posting) {
            detailForm.setFieldsValue({
                id: posting.id,
                stationName: posting.stationName,
                slipNumber: posting.slipNumber || '-',
                pickupDate: moment(posting.pickupDate).locale("ja").format("YYYY年M月D日 H:mm"),
                deliveryDate: moment(posting.deliveryDate).locale("ja").format("YYYY年M月D日 H:mm"),
                maxTruckCapacity: posting.maxTruckCapacity,
                vehicleType: posting.vehicleType,
                price: posting.price,
                memo: posting.remarks,
                postedBy: posting.postedBy?.name,
                deliverer: posting.deliverer?.lastName + ' ' + posting.deliverer?.firstName,
            });
        }
    }
    , [posting, detailForm])

    return (
        <Drawer
            placement="right"
            open={detailDrawerOpen}
            width={isMobile() ? '100vw' : 500}
            onClose={() => setDetailDrawerOpen(false)}
            title={
                parseInt(currentUser.id) ===
                    parseInt(posting.deliverer?.id) && (
                    <Button onClick={handlePostingNav} type="primary">
                        運行スケジュールに追加
                    </Button>
                )
            }
            style={{ zIndex: 1002 }}
            extra={
                <>
                    <Tag color='volcano'>ID {posting.id}</Tag>
                    {taggify(posting.status)}
                        {posting.status !== "archived" ? (
                            <Popconfirm
                                title="リクエストを削除しますか？"
                                okText="削除"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    archiveFreightPosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "archived",
                                            },
                                        },
                                    });
                                }}
                            >
                                <DeleteOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        ) : (
                            <Popconfirm
                                title="リクエストを復元しますか？"
                                okText="復元"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    updateFreightPosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "posted",
                                            },
                                        },
                                    });
                                }}
                            >
                                <EditOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        )}
                </>
            }
        >
            <CommentList
                posting={posting}
                commenterIds={commenterIds}
                setSelectedCommenterId={setSelectedCommenterId}
                setOpenCommentDrawer={setOpenCommentDrawer}
                userListData={commenterUsers}
            />
            <CommentDrawer
                open={openCommentDrawer}
                setOpen={setOpenCommentDrawer}
                comments={comments}
                selectedCommenterId={selectedCommenterId}
                toUser={commenterUsers?.find(user => user?.id === selectedCommenterId)}
                postingId={posting.id}
                createComment={createFreightPostingComment}
                updateComment={updateFreightPostingComment}
                deleteComment={deleteFreightPostingComment}
            />
            <>{!isMobile() ? (<>
                {posting && columnList.map((label, index) => {
                    return (
                        <Row key={index}>
                            <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderTop: index === 0 && '1px solid lightgray', alignContent: 'center'}}>
                                {label}
                            </Col>
                            <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', borderTop: index === 0 && '1px solid lightgray', alignContent: 'center'}}>
                                {posting[labelNameMapping[label]]}
                            </Col>
                        </Row>
                    )}
                )}
                <div style={{width: "100%"}}>
                    {
                        // If the posting is a draft and the user is the poster, show the memo input field.
                        posting.status === "draft" ? (
                            parseInt(posting.postedBy?.id) ===
                                parseInt(currentUser.id) && (
                                <Row>
                                    <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '5rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                        荷物の詳細
                                    </Col>
                                    <Col span={16} style={{ height: '5rem', alignContent: 'center'}}>
                                        {handleMemoInput(posting)}
                                    </Col>
                                </Row>
                            )
                        ) : (
                            // if its not a draft, show the memo.
                            <Row>
                                <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '5rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                    荷物の詳細
                                </Col>
                                <Col span={16} style={{ height: '5rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', alignContent: 'center', overflow: 'auto'}}>
                                    {posting.remarks}
                                </Col>
                            </Row>
                        )
                    }
                    {posting.deliverer && (
                        <Row>
                            <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                配送者
                            </Col>
                            <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', alignContent: 'center'}}>
                                {`${posting.deliverer?.lastName} ${posting.deliverer?.firstName}`}
                            </Col>
                        </Row>
                    )}
                    {handlePostButton(posting)}
                    {posting.status === "posted" &&
                        // If the user is the poster, show the deliverer selection field
                        parseInt(posting.postedBy?.id) ===
                            parseInt(currentUser.id) && (
                            <Row>
                                <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                    配送者
                                </Col>
                                <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', alignContent: 'center'}}>
                                    {handleDriverSelect(posting)}
                                </Col>
                            </Row>
                        )}
                    {handleFinishDelivery(posting)}
                <Divider />
                </div>
            </>)
            :
            (<div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Form
                    style={{width: "100%"}}
                    form={detailForm}
                >
                    <Form.Item label="営業所" name="stationName" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="伝票番号" name="slipNumber" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="納品日時" name="pickupDate" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="集荷日時" name="deliveryDate" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="積載重量" name="maxTruckCapacity" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="車種" name="vehicleType" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    <Form.Item label="運賃" name="price" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    {
                        // If the posting is a draft and the user is the poster, show the memo input field.
                        posting.status === "draft" ? (
                            parseInt(posting.postedBy?.id) ===
                                parseInt(currentUser.id) && (
                                <Form.Item label="備考" name="memo" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                                    {handleMemoInput(posting)}
                                </Form.Item>
                            )
                        ) : (
                            // if its not a draft, show the memo.
                            <Form.Item label="備考" name="memo" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                                <Input readOnly style={readOnlyStyle} value={memo}/>
                            </Form.Item>
                        )
                    }
                    <Form.Item label="投稿者" name="postedBy" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                        <Input readOnly style={readOnlyStyle}/>
                    </Form.Item>
                    {posting.deliverer && (
                        <Form.Item label="配送者" name="deliverer" labelCol={{ style: labelStyle }} style={{ marginBottom: 0 }}>
                            <Input readOnly style={readOnlyStyle}/>
                        </Form.Item>
                    )}
                    {handlePostButton(posting)}
                    {posting.status === "posted" &&
                        // If the user is the poster, show the deliverer selection field
                        parseInt(posting.postedBy?.id) ===
                            parseInt(currentUser.id) && (
                            <div style={{ marginTop: '1rem'}}>
                                {handleDriverSelect(posting)}
                            </div>
                    )}
                    {handleFinishDelivery(posting)}
                </Form>
            </div>
            )
        }</>
        </Drawer>
    );
}

export default DetailDrawer;