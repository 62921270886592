import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Card,
    Form,
    Typography,
    Spin,
    Space,
    Select,
    Row,
    Col,
	InputNumber,
	Divider,
	DatePicker,
	Checkbox
} from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_STATIONS, GET_LABELS, GET_TRUCK_TYPES } from '../../queries';
import NewHeatmapSchedule from './NewHeatmapSchedule';
import { useStickyState } from '../../Utilities/useStickyState';
import { localeInDatepicker } from "../../Utilities/datepickerWithTimeInForm";

import * as moment from 'moment';

const handleDisabledDate = current => {
	return current <= moment().startOf('day') || current > moment().startOf('day').add(8, 'days')
};

const TruckSchedule = ({ history }) => {
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
	const { data: truckTypesData } = useQuery(GET_TRUCK_TYPES);
    const [stations, setStations] = useStickyState([], 'truckScheduleStations')
	const [currentlySelectedStations, setCurrentlySelectedStations] = useState(stations)
	const [getLabelsData] = useLazyQuery(GET_LABELS, {
		variables: {
			stationId: stations,
		},
		onCompleted: (data) => {
			let options = []
			data.mostFrequentlyUsedLabels.forEach((label) => {
				const conbinedLabel = label.label.join('|')
				const stationId = label.stationId
				const stationName = stationsData.stations.edges.filter((s) => s.node.id === stationId)[0].node.officialName
				const option = {label: conbinedLabel, value: conbinedLabel}
				const stationIndex = options.findIndex((option) => option.label === stationName)
				if (stationIndex > -1) {
					options[stationIndex].options.push(option)
				} else {
					options.push({label: stationName, options: [option]})
				}
			})
			setLabelOptions(options)
		}
	});
	const [labelOptions, setLabelOptions] = useState([])
	const [detailFilter, setDetailFilter] = useState({})
    const [date, setDate] = useState(moment())
    const [loading, setLoading] = useState(false)
	const [showAllFilters, setShowAllFilters] = useState(false)
    const [form] = Form.useForm();

	const [transferData, setTransferData] = useState({})
	
	const handleFormSubmit = useCallback ((values) => {
		const { stations, vehicleType, maxTruckCapacityStart, maxTruckCapacityEnd, labels, parkedLoad, truckSize } = values
		setDetailFilter({ vehicleType, maxTruckCapacityStart, maxTruckCapacityEnd, labels, parkedLoad, truckSize })
		setStations(stations);
		setDate(date);
	}, [setStations, date])
	
	useEffect(() => {
		if (transferData.departureTime) {
			form.setFieldsValue({dateRange: [moment(transferData.departureTime), moment(transferData.arrivalTime)]})
			form.setFieldValue('filterAvailable', transferData.filterAvailable)
			handleFormSubmit({ ...form.getFieldsValue(), dateRange: [moment(transferData.departureTime), moment(transferData.arrivalTime)], filterAvailable: transferData.filterAvailable })
		}
	}, [transferData, form, handleFormSubmit])
	
	useEffect(() => {
		if (history.location?.station_id) {
			setStations([history.location.station_id])
		}
	}, [history.location, setStations])

	useEffect(() => {
		if (currentlySelectedStations) {
			getLabelsData({variables: {stationId: currentlySelectedStations}})
		}
	}, [currentlySelectedStations, getLabelsData])

    if (loadingStations) {
        return <Spin/>;
    }

	const initialStations = history.location.station_id ? [history.location.station_id] : stations

	
	
    return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<Card bordered={false}>
				<Form
					form={form}
					layout="inline"
					size="large"
					onFinish={(values) => {
						handleFormSubmit(values);
					}}>
					<Row gutter={[16, 8]} style={{ marginBottom: ".5rem", width: "100%"}}>
						<Col flex>
							<Form.Item
								name="stations"
								label="営業所"
								initialValue={initialStations}
								rules={[{ required: true, message: "営業所を選択してください。" }]}>
								<Select showSearch mode="multiple" placeholder="選択してください" style={{ width: 200 }} onChange={e => setCurrentlySelectedStations(e)}>
									{stationsData.stations.edges.map((station) => (
										<Select.Option key={station.node.id} value={station.node.id}>
											{station.node.officialName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item name='dateRange' label="表示期間">
								<DatePicker.RangePicker
									locale={localeInDatepicker}
									disabledDate={handleDisabledDate}
									showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]}}
									format="YYYY-MM-DD HH:mm:ss"
								/>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item name="filterAvailable" label="空きトラック" valuePropName="checked">
								<Checkbox />
							</Form.Item>
						</Col>
						<Col flex="auto" style={{ display: "flex", alignItems: "center", justifyContent: "end"}}>
							<Button size="small" color="blue" type="outlined" onClick={(() => setShowAllFilters(!showAllFilters))} icon={showAllFilters ? <DownCircleOutlined /> : <UpCircleOutlined/>}>
								詳細検索
							</Button>
							<Form.Item shouldUpdate style={{ marginLeft: "1rem"}}>
								{() => (
									<>
										<Button htmlType="submit" type="primary">
											検索
										</Button>
										{loading && <Spin />}
									</>
								)}
							</Form.Item>
						</Col>
					</Row>
					{ showAllFilters && <Row gutter={[16, 8]}>
						<>
							<Col>
								<Form.Item
									name="vehicleType"
									label="車種"
									rules={[{ required: false, message: "車種を選択してください。" }]}
									>
									<Select
										showSearch
										allowClear
										placeholder="選択してください"
										style={{ width: 165 }}
										options={truckTypesData?.truckTypes.map((truckType) => ({label: truckType, value: truckType}))}
									/>
								</Form.Item>
							</Col>
							<Col style={{display: 'flex'}}>
								<Form.Item
									name="maxTruckCapacityStart"
									label="重量"
									rules={[{ required: false, message: "重量を選択してください。" }]}
									style={{margin: 'inherit'}}
									>
									<InputNumber min={0} max={25000} step={1000}/>
								</Form.Item>
								<Typography style={{margin: '.5rem'}}>〜</Typography>
								<Form.Item
									name="maxTruckCapacityEnd"
									rules={[{ required: false, message: "重量を選択してください。" }]}
									style={{margin: 'inherit'}}
									>
									<InputNumber min={0} max={25000} step={1000}/>
								</Form.Item>
								<Typography style={{margin: '.5rem'}}>kg</Typography>
							</Col>
							<Col>
								<Form.Item
									name="labels"
									label="ルート名"
									rules={[{ required: false, message: "ルート名を選択してください。" }]}
									>
									<Select
										showSearch
										allowClear
										mode="multiple"
										placeholder="選択してください"
										style={{ width: 165 }}
										options={labelOptions}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item
									name="parkedLoad"
									label="荷物"
									rules={[{ required: false, message: "荷物を選択してください。" }]}
									>
									<Select
										showSearch
										allowClear
										placeholder="選択してください"
										style={{ width: 165 }}
										options={[{label: '荷あり', value: '荷あり'}, {label: '空', value: '空'}]}
									/>
								</Form.Item>
							</Col>
						</>
						<Col>
							<Form.Item
								name="truckSize"
								label="トラックサイズ"
								rules={[{ required: false, message: "トラックサイズを選択してください。" }]}
								>
								<Select
									showSearch
									allowClear
									placeholder="選択してください"
									style={{ width: 165 }}
									mode="multiple"
									options={[{label: '大型', value: '大型'}, {label: '中型', value: '中型'}, {label: '小型', value: '小型'}, {label: 'NoTruck', value: ""}]}
								/>
							</Form.Item>
						</Col>
					</Row>}
				</Form>
			</Card>
			<Divider style={{ margin: 0, padding: 0 }}/>
			<Space direction="vertical" style={{ width: "100%", padding: '0px 24px 0px 24px' }}>
				{stations.length > 0 &&
					<NewHeatmapSchedule
						stations={stations}
						dateRange={form.getFieldValue('dateRange')}
						filterAvailable={form.getFieldValue('filterAvailable')}
						stationsData={stationsData}
						stationNames={stationsData.stations.edges.filter((s) => stations.includes(s.node.id))}
						date={date}
						setLoading={setLoading}
						detailFilter={detailFilter}
						transferData={transferData}
						setTransferData={setTransferData}
					/>
				}
			</Space>
		</Space>
	);
};

export default TruckSchedule;
